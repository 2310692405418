import { hasAttribute, meta } from 'lib/dom.js'
import { dispatch, on, stop } from 'lib/events.js'

const tld = window.location.hostname.split('.').slice(-2).join('.')
const domainRegex = RegExp(`(enft.us|oftweb.app|${ window.location.hostname }|${ tld })`)

const speedbump = meta('speedbump')

on(document, 'click', (clickEvent) => {
	const target = clickEvent.target

	if (target.nodeName === 'A' && (target.href || '') !== '' && clickEvent.cancelable && (target.protocol || '').indexOf('http') > -1 && !hasAttribute(target, 'data-no-speedbump') && !domainRegex.test(target.hostname)) {
		stop(clickEvent)

		if (confirm(speedbump)) {
			dispatch(target, 'click', { cancelable: false })
		}
	}
})
